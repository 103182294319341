import request from '@/utils/request'

export function getInvestStrategy(data = {}) {
  return request({
    url: '/investStrategy/',
    method: 'post',
    data
  })
}

export function getSpecialTopic(data = {}) {
  return request({
    url: '/investStrategy/specialTopic',
    method: 'post',
    data
  })
}

export function getInvestOutlook(data = {}) {
  return request({
    url: '/investStrategy/investOutlook/',
    method: 'post',
    data
  })
}

export function getForum(data = {}) {
  return request({
    url: '/investStrategy/investOutlook/forum',
    method: 'post',
    data
  })
}

export function getArticle(data = {}) {
  return request({
    url: '/investStrategy/article',
    method: 'post',
    data
  })
}
