<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 10:42:43
 * @Description: 文章详情pdf
-->
<template>
  <div style="min-width:1200px">
    <iframe :src="pSrc" width="100%" :style="{'height':myHeight}"></iframe>
  </div>
</template>

<script>
import { getArticle } from '@/api/investStrategy'
import { getForum } from '@/api/investStrategy'
export default {
  name: 'newsPdf',
  data() {
    return {
      title: '',
      pdf: null,
      myHeight:'',
      pSrc:''
    }
  },
  created() {
    this.myHeight = document.body.offsetHeight + 'px'
    // getArticle({ id: this.$route.query.id }).then((res) => {
    //   this.pdf = res.article.attachmentList[0].url.replace('https', 'http')
    //   this.loadPDF(this.pdf)
    //   console.log(res)
    // })
    this.loadPDF(this.$route.query.src)
  },
  computed: {},
  methods: {
    loadPDF(e) {
      let baseurl = e
      //ie有缓存加个随机数解决 + '?r=' + new Date()
      let pSrc = baseurl + '?r=' + new Date()
      this.pSrc =
        '../../web/viewer.html?file=' + encodeURIComponent(pSrc) + '.pdf'
    },
  },
}
</script>

<style lang='scss' >
.nav,
.footer_out {
  display: none;
}
</style>